<template>
  <div
    class="flex group bg-white p-1.5 lg:p-3 ring ring-inset ring-border/30 rounded-4 lg:rounded-5 shadow[0_4px_14px_0] shadow-black/6 hover:shadow-[0_6px_18px_0] hover:shadow-black/16 cursor-pointer"
    :class="type == 'default' ? 'flex-col' : 'max-lg:items-center lg:flex-col gap-2.5 lg:gap-4'"
    @click="!disblePopapOpen && getAdditionalIngredients()"
  >
    <div
      class="lg:aspect-61/54 rounded-2.5 lg:rounded-3 overflow-hidden relative"
      :class="type == 'default' ? 'w-full aspect-150/133' : 'aspect-10/9 w-25 lg:w-full h-fit'"
    >
      <div class="relative" itemscope itemtype="http://schema.org/ImageObject">
        <NuxtImg
          itemprop="contentUrl"
          v-if="product.img_path"
          class="w-full h-full object-cover object-center"
          :src="product.img_path"
          :alt="`${product.title} | SakhFood`"
          loading="lazy"
          width="183"
          height="162"
          fit="crop"
        />
        <DefaultImg
          v-else
          class="w-full h-full"
        />
        <meta itemprop="width" :content="String(183)" />
        <meta itemprop="height" :content="String(162)" />
        <div
          v-show="!isOnlineRef"
          class="absolute inset-0 bg-black/50 blur"
        ></div>
        <span
          v-if="!isOnlineRef"
          class="p-1 rounded-1 absolute bottom-1 right-1 text-#fff text-3 bg-black"
        >Ресторан закрыт</span>
      </div>

      <!--      <div class="flex flex-wrap gap-1 absolute inset-1.25 lg:inset-1.5 items-start">-->
      <!--        <Tag-->
      <!--          v-for="tag in ['tag1', 'tag2', 'tag3']"-->
      <!--          size="sm"-->
      <!--        >-->
      <!--          {{ tag }}-->
      <!--        </Tag>-->
      <!--      </div>-->
      <ClientOnly>
        <div
          v-if="countProduct > 0"
          class="absolute inset-0 bg-firmennyy/60 flex flex-col gap-2.5 items-center justify-center"
          :class="type === 'default' ? '' : 'max-lg:hidden'"
        >
          <div class="flex items-center justify-center rounded-full min-w-10.5 p-1 bg-white/20 backdrop-blur-40 aspect-square text-white">
            <span
              v-if="countProduct == 1"
              class="text-5.5 i-custom:checkmark block"
            ></span>
            <span
              v-else
              class="text-6.5 leading-[1.125rem]"
            >{{ countProduct }}</span>
          </div>
          <span
            v-show="!isOnlineRef"
            class="p-1 rounded-1 absolute bottom-1 right-1 text-#fff text-3 bg-black"
          >Ресторан закрыт</span>
          <span class="text-center text-white leading-1 text-3.5 font-700">Добавлено <br> в корзину</span>
        </div>
      </ClientOnly>
      <WishBtn
        :id="product.id"
        class="parent absolute top-2 left-2 drop-shadow-[0_5px_5px_rgba(0_0_0/.25)] text-transparent"
        wish-type="products"
        #="{ isWish }"
      >
        <ClientOnly>
          <span
            class="text-4.5 i-custom:heart-fill?mask parent-hover:text-firmennyy absolute top-px left-px transition-colors"
            :class="{ 'text-firmennyy': isWish }"
          ></span>
        </ClientOnly>
        <span class="text-5 i-custom:heart text-white z-1"></span>
      </WishBtn>
    </div>
    <div
      class=" flex flex-col gap-2 lg:gap-3 text-tekstovyy grow"
      :class="{ 'mt-2.5 lg:mt-4': type === 'default' }"
    >
      <p
        v-if="restaurantTitleIsShow"
        class="text-3.25 lg:text-3.75 font-700"
      >
        {{ product.restaurant.title }}
      </p>
      <p itemprop="name" class="text-3.75 lg:text-4.25 grow group-hover:text-firmennyy">{{ product.title }}</p>
      <p class="text-seryy text-3.25 lg:text-3.75">
        {{ product.size_title }}
      </p>
      <p itemprop="offers" itemscope itemtype="http://schema.org/Offer" class="text-5 lg:text-5.5 font-500">
        <span itemprop="price">{{ product.price }}</span> <span itemprop="priceCurrency">₽</span></p>
    </div>
    <AddToCartBtn
      v-if="props.isOnline && !product.has_ingredients && product.is_currently_selling && product.is_active"
      :key="countProduct"
      :class="type === 'default' ? '' : 'max-lg:hidden'"
      class="[&>div]:!mt-0 mt-3 w-full"
      :product="product"
      :count="countProduct"
      @click.stop
    />
    <RestaurantCartBtnPlaceholder
      v-if="product.has_ingredients && product.is_currently_selling && product.is_active"
      :class="type === 'default' ? '' : 'max-lg:hidden'"
      class="mt-3 w-full"
      :count="countProduct"
    />
    <div
      v-if="!product.is_active || !product.is_currently_selling"
      class=" inset-0 flex items-center justify-center bg-black/70 rounded-4 lg:rounded-6"
    >
      <span class="text-center text-white leading-1 text-4 lg:text-4.5 font-700">
        Снято с продажи
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductType } from '~/composables/product/types/UseProductType';
import { useProduct } from '~/composables/product/useProduct';
import { useProductPopup } from '~/composables/popups/useProductPopup';
import { useCart } from '~/composables/cart/useCart';
import WishBtn from '~/components/widgets/wish-btn/WishBtn.vue';
import AddToCartBtn from '~/components/widgets/add-to-cart/AddToCartBtn.vue';

const { getProductBySlug } = useProduct();
const { openPopup } = useProductPopup();
const { cartList } = useCart();

const props = defineProps({
  product: { type: Object as PropType<ProductType>, required: true },
  type: { type: String as PropType<'default' | 'review'>, default: 'default' },
  restaurantTitleIsShow: { type: Boolean, default: false },
  disblePopapOpen: { type: Boolean, default: false },
  isOnline: { type: Boolean, default: true }
});

const isOnlineRef = ref<boolean>(false);
onMounted(() => {
  try {
    isOnlineRef.value = !(props.product.restaurant.is_online === 0);
    return;
  } catch {
    isOnlineRef.value = props.isOnline;
  }
});

const countProduct = ref(0);

watch(
  () => cartList.value.map((item) => item.cart_products), // создаем массив всех продуктов во всех cartItems
  (newProductsArrays) => {
    // обрабатываем каждый массив продуктов
    for (const products of newProductsArrays) {
      const product = products.find((product) => product.product_id === props.product.id);
      if (product) {
        countProduct.value = product.count;
        return; // прерываем цикл, так как продукт найден
      }
    }
    countProduct.value = 0; // если продукт не найден во всех cartItems
  },
  {
    deep: true, // глубокое отслеживание изменений в массивах
    immediate: true
  }
);
const route = useRoute();
async function getAdditionalIngredients () {
  if (!props.product.is_active || !props.product.is_currently_selling) {
    return;
  }
  const res = await getProductBySlug(props.product.slug)
    .catch((e) => {
      console.log(e._data.message);
      return null;
    });

  if (res) {
    await navigateTo({
      query: {
        ...route.query,
        // product_slug: props.product.slug
      },
      params: { isScrollTo: 'false' }
    });
    openPopup({
      currentProduct: res
    });
  }
}
</script>
